import React from "react";
import { useParams } from "react-router-dom";

export const TypeformRegisterPage: React.FC = () => {

    const { companyCode } = useParams<{ companyCode: string }>();
    const TYPEFORM_FORM_ID='SadIMlf1';
    const iframeSrc = `https://journey-live.typeform.com/to/${TYPEFORM_FORM_ID}#company=${companyCode}`;
    
    return (
        <iframe src={iframeSrc} style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
    );
}
